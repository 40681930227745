var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Import excel","size":"lg","show":_vm.show},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('TButton',{attrs:{"icon":"cil-check","content":"Confirm","disabled":!_vm.items.length || _vm.confirmLoading},on:{"click":_vm.confirm}})]},proxy:true}])},[_c('TInputFile',{attrs:{"value":_vm.file,"accept":".xlsx","multiple":false},on:{"update:value":function($event){_vm.file=$event}}}),_c('TButton',{staticClass:"my-2 ml-auto d-flex",attrs:{"content":"Import","icon":"cil-plus","disabled":!_vm.file.length || _vm.loading},on:{"click":_vm.importData}}),_c('TTableSimple',{attrs:{"fields":_vm.itemFields,"items":_vm.items,"loading":_vm.loading},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var index = ref.index;
return [_c('td',[_c('TButtonRemove',{on:{"click":function($event){return _vm.removeItem(index)}}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'text-danger': item.is_error }},[_c('TMessageText',{attrs:{"editable":"","value":item.customer_id},on:{"change":function($event){item.customer_id = $event}}})],1)]}},{key:"order_id",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'text-danger': item.is_error }},[_c('TMessageText',{attrs:{"editable":"","value":item.order_id},on:{"change":function($event){item.order_id = $event}}})],1)]}},{key:"shipping_code",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'text-danger': item.is_error }},[_c('TMessageText',{attrs:{"editable":"","value":item.shipping_code},on:{"change":function($event){item.shipping_code = $event}}})],1)]}},{key:"box_id",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'text-danger': item.is_error }},[_c('TMessageText',{attrs:{"editable":"","value":item.box_id},on:{"change":function($event){item.box_id = $event}}})],1)]}},{key:"quantity",fn:function(ref){
var item = ref.item;
return [_c('td',{class:{ 'text-danger': item.is_error }},[_c('TMessageNumber',{attrs:{"editable":"","value":item.quantity ? item.quantity : 0},on:{"change":function($event){item.quantity = $event}}})],1)]}}])}),(_vm.items.length)?_c('TButton',{attrs:{"content":"Remove all","icon":"cil-x-circle","color":"danger"},on:{"click":function($event){_vm.items = []}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }